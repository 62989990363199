<template>
  <div class="song-list">
    <Songs class="song"
           v-for="(song,index) in songs"
           :key="index" :song="song">
    </Songs>
    <p class="medium no-song pt-4 pb-4"
       v-if="songs.length === 0">
      No Song
    </p>
  </div>
</template>
<script>
  const Songs = () => import('./Song.vue');
  export default {
    props : {
      songs : Array,
    },
    name       : 'SongList',
    components : {
      Songs,
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/challenges_music/song-list";
</style>